<template>
  <div>
    <v-img :src="require(isDark ? '@/assets/futflogga_dark.png' : '@/assets/futflogga.png')"
    style="margin: 2rem"></v-img>

    <v-container>
      <h1>Om FUTF</h1>
    </v-container>

    <paragraph-component>
      <textloader-component textKey="FUTFBrödtext"/>
    </paragraph-component>
    <br>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="5" class="order-sm-2">
        <v-img
        :aspect-ratio="7/10"
        :src="require('@/assets/Information/Futf/utb-utskottet.jpg')" contain height=30em></v-img>
      </v-col>
      <v-col cols="12" sm="5" class="order-sm-1">
        <paragraph-component title="Kurs- och skyddsombud">
          <textloader-component textKey="UtbBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>
    <br>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="5">
        <v-img
        :aspect-ratio="10/6"
        :src="require('@/assets/Information/Futf/arb-utskott.png')"></v-img>
      </v-col>
      <v-col cols="12" sm="5">
        <paragraph-component title="Arbetsmarknadsutskottet">
          <textloader-component textKey="ArbBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>
    <br>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="5" class="order-sm-2">
        <v-img
        :aspect-ratio="16/11"
        :src="require('@/assets/Information/Futf/fredagsfikat.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="5" class="order-sm-1">
        <paragraph-component title="Fredagsfikat">
          <textloader-component textKey="FredagsfikatBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>
    <br>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="5">
        <v-img
        :aspect-ratio="10/6"
        :src="require('@/assets/Information/Futf/klubbverket.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="5">
        <paragraph-component title="Klubbverket">
          <textloader-component textKey="KVBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>
    <br>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="5" class="order-sm-2">
        <v-img
        :aspect-ratio="10/6"
        :src="require('@/assets/Information/Futf/träningsverket.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="5" class="order-sm-1">
        <paragraph-component title="Träningsverket">
          <textloader-component textKey="TräningsverketBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>
    <br>

    <v-row justify="center" align="center">
      <v-col cols="12" sm="5">
        <v-img
        :aspect-ratio="10/6"
        :src="require('@/assets/Information/Futf/snoIT.jpg')"></v-img>
      </v-col>
      <v-col cols="12" sm="5">
        <paragraph-component title="IT-gruppen">
          <textloader-component textKey="ITgruppenBrödtext"/>
        </paragraph-component>
      </v-col>
    </v-row>
    <br>

    <v-row justify="center" align="center">
      <v-col cols ="10" sm="6" md="4">
        <v-img :src="
          require(isDark
            ? '@/assets/utn_vit.png'
            : '@/assets/utn_svart.png')"></v-img>
      </v-col>
    </v-row>

    <paragraph-component title="UTN">
      <textloader-component textKey="FUTFBrödtextUTN"/>
    </paragraph-component>

    <v-row justify="center" align="center">
      <v-col cols ="10" sm="8" md="6">
        <v-img :src="require('@/assets/Information/Futf/utn_flagga.jpg')"></v-img>
      </v-col>
    </v-row>
    <br>

  </div>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'

export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  computed: {
    isDark: function () { return this.$vuetify.theme.dark }
  }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>
